<template>
    <div id="orderInfo">
        <template v-if="showResult">
            <express-info-v1
                    v-if="stepInfo && stepInfo.step && stepInfo.step.length > 0"
                    :stepInfo="stepInfo"
                    style="margin-top: 10px;"
            ></express-info-v1>
            <div class="goods-info" style="margin-top: 10px;">
                <div class="goods">
                    <div class="left">
                        <img :src="goodsInfo.cover">
                    </div>
                    <div class="right">
                        <div class="title">{{goodsInfo.title}}</div>
                        <div class="express-type">
                            {{goodsInfo.express_type_id == 1 ? '自提点提货' : '快递配送'}}
                        </div>
                        <div class="price">价值{{goodsInfo.market_price}}元</div>
                    </div>
                </div>
                <div class="express-info">
                    <cell-v3 :hide-icon="true" class="cell-div">
                        <div slot="title">运费</div>
                        <div class="actions">¥{{goodsInfo.shipping_fee}}</div>
                    </cell-v3>
                    <cell-v3 :hide-icon="true" class="cell-div">
                        <div slot="title">实付费</div>
                        <div class="actions actionBold">¥{{goodsInfo.real_pay}}</div>
                    </cell-v3>
                    <cell-v3 :hide-icon="true" class="cell-div" v-if="goodsInfo.pick_up_code_url">
                        <div slot="title">提货码</div>
                        <div class="actions" @click="showQrcode">
                            <img src="https://api.mengjingloulan.com/storage/uploads/20211120/9e643e2aee466f9a7ffca89f5efccce3.png"/>
                        </div>
                    </cell-v3>
                </div>
            </div>
        </template>

        <nut-popup
                v-model="showVisible"
                :closeable="true"
                :round="true"
                :close-on-click-overlay="false"
                position="bottom"

        >
            <div class="popup-window">
                <div class="title">请出示如下二维码给自提点员工进行核销</div>
                <div class="img">
                    <img :src="goodsInfo.pick_up_code_url"/>
                </div>
                <div class="warning">或提供 <span class="code">{{goodsInfo.pick_up_code}}</span> 给自提点员工</div>
            </div>
        </nut-popup>
    </div>
</template>

<script>
  import ExpressInfoV1 from '@T/community/orders/expressInfoV1'
  import goodsList from '@T/community/goodsList/goodsListV1'
  import cellV2 from '@T/community/cell/cellV2.vue'
  import CellV3 from '@/template/community/cell/cellV3'

  export default {
    name: 'card-express-info',
    components: {
      CellV3,
      ExpressInfoV1,
      goodsList,
      cellV2,
    },
    data () {
      return {
        showVisible: false,
        canContinue: false,
        showResult: false,
        orderId: 0, //订单id 默认为0
        //订单详情
        orderInfo: {
          id: 0, //订单id
          store_id: 0, //店铺id
          status: 0, //
          create_time: '', //订单创建时间
          order_amount: '0.00', //支付金额
          goods_amount: '0.00', //订单金额
          deduct_amount: '0.00', //减免金额
          order_sn: '', //订单号
          goodList: [], //类型：Array  必有字段  备注：订单列表 //refund_status: -1 没有申请过 0：已经提交了申请 1:已经退款成功 2:退款被拒绝
        },
        //配送信息
        stepInfo: {
          title: '', //步骤标题
          //时间轴
          step: [
            // {
            //   title: "用户确认收货时间", //提示
            //   time: "2021-11-16 03:49", //时间轴
            // },
          ],
          //负责人信息
          fuzeren: {
            type: 0, //1 是配送员信息 2是站点信息
            name: '', //联系人
            site_name: '', //站点名称
            mobile: '', //电话
            address: '', //地址
            location: '', //经纬度
            am_start_time: '', //上午营业开始时间
            am_end_time: '', //上午营业结束时间
            pm_start_time: '', //下午营业开始时间
            pm_end_time: '', //下午营业结束时间
          },
        },
        //自提码
        extends: {
          id: 0, //工作流程
          pick_up_code: '', //提货码
          pick_up_code_url: '', //提货码图片地址
        },
      }
    },
    mounted () {
      document.title = '配送详情'
      if (this.$route.query?.id) {
        this.orderId = this.$route.query.id
      }
      this.getOrderInfo()
      this.canContinue = true
    },
    computed: {
      workTime () {
        let person = this.stepInfo.fuzeren
        if (person.am_start_time) {
          return `${person.am_start_time}-${person.am_end_time} ${person.pm_start_time}-${person.pm_end_time}`
        } else {
          return ''
        }
      },
    },
    methods: {
      showQrcode () {
        //展示提货码
        this.showVisible = true
      },
      //申请退款
      refundMoney (e) {
        let goods_id = e.goods_id
        let order_id = this.orderId
        let query = {
          order_id: order_id,
          goods_id: goods_id,
        }
        if (this.$platform.wxsdk.isWechat()) {
          query = encodeURIComponent(JSON.stringify(query))
          if (this.canContinue) {
            this.canContinue = false
            this.$platform.wxsdk.wxRoute({
              type: 'redirectTo',
              url: '/web/community/apply-for-refund?query=' + query,
            })

            setTimeout(() => {
              this.canContinue = true
            }, 500)
          }

        } else {
          this.$router.push({
            path: 'apply-for-refund',
            query: query,
          })
        }
      },
      //联系负责人
      contact () {
        console.log('联系负责人')
        let self = this
        this.$dialog({
          title: '温馨提示',
          content: `确定要给${self.stepInfo.fuzeren.mobile}打电话吗？`,
          closeOnClickModal: false, //点击蒙层是否关闭对话框
          closeBtn: false, //显式右上角关闭按钮
          onOkBtn () {
            self.$dialog.closed()
            if (this.canContinue) {
              this.canContinue = false
              if (self.$platform.wxsdk.isWechat()) {
                self.$platform.wxsdk.wxRoute({
                  type: 'navigateTo',
                  url: '/web/makePhoneCall/common?mobile=' +
                  self.stepInfo.fuzeren.mobile,
                })
              } else {
                window.location.href = 'tel:' + self.stepInfo.fuzeren.mobile
              }

              setTimeout(() => {
                this.canContinue = true
              }, 500)
            }

          },
        })
      },
      //预览二维码
      showCode () {
        this.$showCodeV1({
          title: '请出示您的提货码', // 标题
          codeUrl: this.extends.pick_up_code_url, //二维码链接地址
          tip: '订单编号：' + this.extends.pick_up_code,
          confirmText: '确定', // 确认文字
          success: () => {
            // 确认执行函数
            console.log('确定')
          },
        })
      },
      //导航
      goMap () {
        let lng_lat = this.stepInfo.fuzeren.location.split(',')
        let lng = Number(lng_lat[1])
        let lat = Number(lng_lat[0])
        let options = {
          lng: lng,
          lat: lat,
          site_name: this.stepInfo.fuzeren.site_name,
          address: this.stepInfo.fuzeren.address,
        }
        options = encodeURIComponent(JSON.stringify(options))
        if (this.$platform.wxsdk.isWechat()) {
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/map/map?query=' + options,
          })
        }
      },
      //获取订单详情
      async getOrderInfo () {
        let params = {
          id: this.orderId,
        }

        let query = await this.$api.couponCard.getOrderExpressInfo(params)
        this.goodsInfo = query.data.goodsInfo
        this.stepInfo = query.data.stepInfo || this.stepInfo
        this.extends = query.data.extends || this.extends
        this.showResult = true
      },
    },
  }
</script>

<style scoped lang="scss">
    #orderInfo {
        display: inline-block;
        width: 100%;
        min-height: 100%;
        background: #f6f6f6;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        .goods-info {
            background: #fff;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            flex-wrap: wrap;

            .goods {
                width: 100%;
                display: flex;
                padding-bottom: 18px;
                border-bottom: #f6f6f6 solid 1px;

                .left {
                    width: 98px;
                    height: 74px;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
                    border-radius: 8px;
                    overflow: hidden;
                    margin-right: 12px;

                    img {
                        width: 98px;
                        height: 74px;
                    }
                }

                .right {
                    flex: 1;
                    display: flex;
                    flex-wrap: wrap;

                    .title {
                        width: 100%;
                        color: #333333;
                        font-size: 14px;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        word-break: break-all;
                    }

                    .express-type {
                        width: 100%;
                        color: #A2A2A2;
                        font-size: 14px;
                    }

                    .price {
                        width: 100%;
                        color: #C10000;
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }

            .express-info {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                .cell-div {
                    width: 100%;
                    margin-top: 18px;
                    font-size: 14px;
                    color: #333333;

                    .actionBold {
                        color: #C10000;
                        font-size: 16px;
                        font-weight: bold;
                    }

                }
            }

        }
    }

    .popup-window {
        padding: 50px 30px;
        display: flex;
        flex-wrap: wrap;

        .title {
            width: 100%;
            font-size: 14px;
            color: #333;
        }

        .img{
            width: 100%;

            img{
                width: 100%;

            }
        }

        .warning{
            width: 100%;
            font-size: 14px;
            color: #333;
            align-items: center;

            .code{

                color: #108ee9;
                font-size: 18px;
                font-weight: bold;
            }
        }
    }
</style>
