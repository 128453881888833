<template>
  <div class="goodsListV1">
    <template v-if="showStatus == 1">
      <div class="goods-header">
        <div class="express-name">
          快递名称：{{ expressInfo.company_name }}

          <button
            class="receive-btn"
            @click="receiveOrderByExpress"
            v-if="status_1_can_receive"
          >
            确认收货
          </button>
        </div>
        <div class="express-sn">快递单号：{{ expressInfo.order_sn }}</div>
      </div>
      <div v-for="(item, index) in showList" :key="index">
        <goodsV2
          :img_url="item.cover"
          :description="item.title"
          :num="item.total"
          :unit_price="item.goods_amount"
          :total_price="item.total_amount"
          :verification-status="item.verification_status == 1"
          :showRefund="showRefund"
          :refundLog="item.refund_log"
        >
          <span class="already-receive" v-if="show_receive_btn(item)"
            >已确认收货</span
          >
          <!--                    <button class="btn" @click="refundMoney(item)"-->
          <!--                            v-if="item.return_exchange == 0 && orderInfo.status > 0">-->

          <!--                        &lt;!&ndash; refund_status: -1 没有申请过 0：已经提交了申请 1:已经退款成功 2:退款被拒绝 &ndash;&gt;-->
          <!--                        <div v-if="item.refund_status == -1">申请退款</div>-->
          <!--                        <div v-if="item.refund_status == 0">退款中</div>-->
          <!--                        <div v-if="item.refund_status == 1">退款成功</div>-->
          <!--                        <div v-if="item.refund_status == 2">退款拒绝</div>-->
          <!--                    </button>-->
          <button
            class="btn"
            @click="refundMoney(item)"
            v-if="item.can_refund > 0"
          >
            <div>申请退款</div>
          </button>
        </goodsV2>
      </div>
    </template>
    <template v-if="showStatus == 0">
      <div v-for="(item, index) in showList" :key="index">
        <goodsV2
          :img_url="item.cover"
          :description="item.title"
          :num="item.total"
          :unit_price="item.goods_amount"
          :total_price="item.total_amount"
          :verification-status="item.verification_status == 1"
          :showRefund="showRefund"
          :refundLog="item.refund_log"
          :specText="item.spec_text"
          :goodsStatusText="item.status_text"
        >
          <span class="already-receive" v-if="show_receive_btn(item)"
            >已确认收货</span
          >
          <button
            v-if="
              orderInfo.order_type == 2 &&
              orderInfo.status > 0 &&
              show_get_btn(item)
            "
            class="btn danger"
            @click="receiveOrder(item)"
          >
            确认收货
          </button>
          <button
            v-if="orderInfo.order_type == 2 && orderInfo.status == 2"
            class="btn"
            @click="lookWuLiu(item)"
          >
            查看物流
          </button>
          <button
            class="btn"
            @click="refundMoney(item)"
            v-if="item.can_refund > 0"
          >
            <div>申请退款</div>
          </button>
        </goodsV2>
      </div>
    </template>
    <div v-if="goodsList.length > 3" class="box-m-t-20">
      <div class="foot" @click="isMore = !isMore">
        <img
          :src="require('@STATIC/image/icons/arrowDownFill.png')"
          :class="{ 'foot-rotate': !isMore }"
        />
        <span class="box-m-l-4">{{ isMore ? "展开更多" : "收起" }}</span>
      </div>
    </div>
    <div
      style="width: 100%; height: 1px; background-color: #ebebeb"
      class="box-m-t-13"
    ></div>
    <cellV2
      class="box-m-t-18"
      :type="3"
      leftText="商品总额"
      :rightText="goods_amount"
    ></cellV2>
    <cellV2
      class="box-m-t-18"
      :type="3"
      leftText="人生券抵扣"
      :rightText="deduct_amount"
    ></cellV2>
    <cellV2
      class="box-m-t-18"
      :type="4"
      leftText="实付费"
      :rightText="order_amount"
    ></cellV2>
    <cellV2
      v-if="shipping_fee>0"
      class="box-m-t-18"
      :type="4"
      leftText="运费"
      :rightText="'¥ '+shipping_fee"
    ></cellV2>
    <cellV2
      class="box-m-t-18"
      :type="3"
      leftText="备注"
      :rightText="remark"
    ></cellV2>
  </div>
</template>

<script>
import goodsV2 from "@T/community/goods/goodsV2";
import cellV2 from "@T/community/cell/cellV2.vue";

export default {
  name: "goodsListV1",
  components: {
    goodsV2,
    cellV2,
  },
  props: {
    goodsList: {
      type: Array,
      default() {
        return [];
      },
    },
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    receiveBtnInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    showRefund: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMore: true,
    };
  },
  computed: {
    showList() {
      // console.log('this.$props.goodsList',this.$props.goodsList)
      if (this.isMore) {
        return this.$props.goodsList.slice(0, 3);
      } else {
        return this.$props.goodsList;
      }
    },
    goods_amount() {
      if (this.orderInfo.goods_amount) {
        return "￥" + this.orderInfo.goods_amount;
      } else {
        return "￥0.00";
      }
    },
    order_amount() {
      if (this.orderInfo.order_amount) {
        return "￥" + this.orderInfo.order_amount;
      } else {
        return "￥0.00";
      }
    },
    shipping_fee() {
      return this.orderInfo.shipping_fee;
    },
    remark() {
      if (this.orderInfo.remark) {
        return this.orderInfo.remark;
      } else {
        return "无";
      }
    },
    deduct_amount() {
      if (this.orderInfo.deduct_amount) {
        return "￥" + this.orderInfo.deduct_amount;
      } else {
        return "￥0.00";
      }
    },
    showStatus() {
      console.log(
        "this.$props?.receiveBtnInfo?.show_status || 0",
        this.$props?.receiveBtnInfo?.show_status || 0
      );
      return this.$props?.receiveBtnInfo?.show_status || 0;
    },
    expressInfo() {
      let info = {};
      let goods_receive_status =
        this.$props.receiveBtnInfo.goods_receive_status;
      for (let i in goods_receive_status) {
        if (typeof info.order_sn == "undefined") {
          info = goods_receive_status[i];
        }
      }
      return info;
    },
    status_1_can_receive() {
      return this.$props?.receiveBtnInfo?.status_1_can_receive || false;
    },
  },
  mounted() {
    console.log("this.showRefund", this.$props.showRefund);
  },
  methods: {
    refundMoney(item) {
      if (item.return_exchange == 1) {
        //0 是可以退款 1是不可退款
        this.$toast.text("当前商品不可退款", { duration: 1000 });
        return;
      }
      if (item.refund_status == -1) {
        this.$emit("refundMoney", item);
      }
    },
    lookWuLiu(item) {
      this.$emit("lookWuLiu", item);
    },
    show_get_btn(item) {
      let goods_id = item.goods_id;
      let goods_receive_status =
        this.$props.receiveBtnInfo?.goods_receive_status || {};
      let show_status = this.$props.receiveBtnInfo?.show_status || 0;
      return (
        show_status == 0 &&
        typeof goods_receive_status[goods_id] != "undefined" &&
        parseInt(goods_receive_status[goods_id].status) == 0
      );
    },
    receiveOrder(item) {
      let goods_id = item.goods_id;
      let goods_receive_status =
        this.$props.receiveBtnInfo?.goods_receive_status[goods_id] || {};
      this.$emit("receiveOrder", {
        ...item,
        express_log: goods_receive_status,
      });
    },
    show_receive_btn(item) {
      let goods_id = item.goods_id;
      let goods_receive_status =
        this.$props.receiveBtnInfo?.goods_receive_status || {};
      return (
        typeof goods_receive_status[goods_id] != "undefined" &&
        goods_receive_status[goods_id].status == 1
      );
    },
    receiveOrderByExpress() {
      return this.$emit("receiveOrderByExpress", this.expressInfo);
    },
  },
};
</script>
<style lang="scss" scoped>
@include b(goodsListV1) {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}

@include b(foot) {
  transition: 0s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 10px;
    height: 10px;
  }
  & > span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
  }
  @include m(rotate) {
    transform: rotate(180deg);
  }
}

@include b(btn) {
  height: 25px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid #939393;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: transparent;
  margin-left: 5px;
}

.danger {
  color: #ff5500;
  height: 25px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid #ff5500;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  background: transparent;
  margin-left: 5px;
}

.already-receive {
  display: flex;
  font-size: 12px;
  color: #ff5500;
  align-items: center;
  margin-right: 10px;
}

.goods-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .express-name {
    width: 100%;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
    position: relative;

    .receive-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #ff5500;
      height: 25px;
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
      border: 1px solid #ff5500;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      background: transparent;
      margin-left: 5px;
    }
  }

  .express-sn {
    width: 100%;
    font-size: 14px;
    color: #939393;
    margin-bottom: 15px;
    border-bottom: rgb(235, 235, 235) solid 1px;
    padding-bottom: 15px;
  }
}
</style>
